<template>
  <div class="annotatorDetails">
    <div v-if="loading || !annotator">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
            <b-col class="mt-5">
              <div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle v-bind:title="annotator.name" :pretitle="'Annotator details'" />
          </b-col>
          <b-col>
            <div class="mt-5 text-right">
              <span v-if="editMode">
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button v-if="$store.getters.checkRole('legam.bib.annotator.delete')" @click="deleteItem" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else>
                <button @click="editMode = true" v-if="$store.getters.checkRole('legam.bib.annotator.modify')" class="btn-legam-xs mb-2">
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button
                  v-if="!$store.getters.checkRole('legam.bib.annotator.modify') && $store.getters.checkRole('legam.bib.annotator.delete')"
                  @click="deleteItem"
                  class="btn-legam-xs mb-2 btn-danger"
                >
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>

        <b-row class="justify-content-md-center">
          <b-col>
            <article>
              <b-row>
                <b-col>
                  <h6>Name</h6>
                  <b-form-input
                    id="name"
                    v-model="annotator.name"
                    placeholder="Name"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="annotator.name"></span>
                </b-col>
              </b-row>

              <hr>

              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-1 class="text-left">
                      Manuscripts <small>({{ annotator.manuscripts.length }} {{ annotator.manuscripts.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-1" accordion="my-accordion-1" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-manuscript>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-manuscript" title="Add manuscript" ok-only ok-variant="secondary" size="lg">
                        <ManuscriptAutocomplete @select="addManuscript" />
                      </b-modal>
                      <ManuscriptTable
                        :showTotal="false"
                        :manuscripts="annotator.manuscripts"
                        @delete="deleteManuscript"
                        :editMode="editMode"
                        v-if="annotator.manuscripts.length"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </article>
          </b-col>
        </b-row>
        <div class="mt-4" v-if="config.editable">
          <h6>Logs</h6>
          <ChangeLogsTable :objectType="'annotator'" :objectId="id" :key="'logs' + index" />
        </div>
      </b-container>
    </div>
  </div>
</template>

<style scoped>
  * >>> th {
    font-size: 10px !important;
    text-transform: uppercase;
    color: #bbbbbb;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import ChangeLogsTable from '@/components/ChangeLogsTable.vue'
  import ManuscriptAutocomplete from '@/components/Bibliography/ManuscriptAutocomplete.vue'
  import ManuscriptTable from '@/components/Bibliography/ManuscriptTable.vue'
  import config from '@/config.js'

  export default {
    name: 'AnnotatorDetails',
    data() {
      return {
        id: this.$route.params.id,
        loading: true,
        editMode: false,
        index: 0,
        modalAnnotator: null,
        config: config,
      }
    },
    components: {
      PageTitle,
      ChangeLogsTable,
      ManuscriptAutocomplete,
      ManuscriptTable,
    },
    computed: {
      ...mapState({
        annotator: state => state.annotator.annotator
      }),
    },
    mounted(){
      this.$store.dispatch('loadAnnotator', this.id)
    },
    watch: {
      'id'(){
        this.loading = true
        this.$store.dispatch('loadAnnotator', this.id)
      },
      'annotator'(){
        this.loading = false
      }
    },
    methods: {
      cancelEdit(){
        this.editMode = false
        this.$store.dispatch('loadAnnotator', this.id)
        this.$store.dispatch('addNotification', {
          type: 'warning',
          text: `Changes dismissed`
        })
      },
      saveEdit(){
        this.editMode = false
        this.$store.dispatch('saveAnnotator', this.annotator).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Changes saved`
          })
          this.index++
        })
      },
      deleteItem(){
        this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
          if (value){
            this.$store.dispatch('deleteAnnotator', this.annotator).then(() => {
              router.push('/annotators')
            })
          }
        })
      },

      addManuscript(manuscript){
        this.$bvModal.hide('modal-add-manuscript')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Manuscript added`
        })
        this.annotator.manuscripts.push(manuscript)
      },
      deleteManuscript(manuscriptId){
        this.annotator.manuscripts = this.annotator.manuscripts.filter(
          manuscript => manuscript.id != manuscriptId
        )
      },
    }
  }
</script>
